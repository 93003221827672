import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '..';

const memoSelector = createSelector(
  [
    (state: RootState) => state.userProfile,
    (state: RootState) => state.studentProfile,
    (_state: RootState, useSearchProfile: boolean) => useSearchProfile,
  ],
  (userProfile, studentProfile, useSearchProfile) => {
    const majorTypes: Record<string, string> = {
      MAJ: 'Major',
    };

    const { search, viewAs } = userProfile;
    let profile: EveryProfile;

    if (useSearchProfile) {
      profile = search.selectedUser?.profile;
    } else if (viewAs.active) {
      profile = viewAs.selectedUser?.profile;
    } else {
      profile = studentProfile.data;
    }

    const major =
      profile?.declaredDegreePrograms.declaredMajors?.[0] ||
      ({
        name: '',
        degree: '',
        type: '',
        campusName: '',
        academicPlanCode: undefined,
      } satisfies Partial<API.StudentData.DegreeProgram>);

    const majorName =
      major.name && major.degree ? `${major.name}, ${major.degree}` : '';
    const majorType = majorTypes[major.type] || 'Major';

    return {
      profile,
      major,
      majorName,
      majorType,
    };
  },
);

const useCurrentProfile = (useSearchProfile = false) => {
  return useAppSelector((state) => {
    return memoSelector(state, useSearchProfile);
  });
};

export { useCurrentProfile };
