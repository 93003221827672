import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '..';

const memoSelector = createSelector(
  (state: RootState) => state.plan,
  (plan) => {
    const { degreePlan } = plan.data;
    const planTermGroups = degreePlan.map((term) => {
      const sequenceIndex = term._uiMetaData!.index;

      return {
        value: term.term,
        label: `Term ${sequenceIndex}: ${term.termName!}`,
        sessions: term.sessions
          .filter((s) => s.sessionName)
          .map((s) => {
            const _uiMetaData = s._uiMetaData!;

            return {
              isDisabled: _uiMetaData.allowMoveInSession === false,
              label: `Session ${_uiMetaData.displayName}`,
              value: `${s.sessionName}`,
              _uiMetaData,
            };
          }),
      };
    });

    return planTermGroups;
  },
);

const useTermSessionSelector = () =>
  useAppSelector((state) => {
    return memoSelector(state);
  });

export { useTermSessionSelector };
