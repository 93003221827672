import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  ThunkDispatch,
} from '@reduxjs/toolkit';

import { NONE_VALUE } from 'core/constants/app-constants';
import {
  getCurrentTerm,
  getTermByRange,
} from 'core/services/dpl/dpl-plan-term-service';
import {
  createPlanCourse,
  deletePlanCourse,
  updatePlanCourses,
} from 'core/services/idp/plan-course-service';
import { getStudentProfile } from 'core/services/idp/student-profile-service';
import {
  preProcessPlanForUI,
  sortPlanByDate,
} from 'core/services/ui/plan-ui-data-service';
import { scrollToClassCard, scrollToMovedClass } from 'core/utils/html-utils';
import { cloneDraftObject } from 'core/utils/structured-clone-utils';
import {
  HttpQueueWorkerManager,
  OnMessageData,
} from 'core/workers/http-queue-worker';
import { startPlanPoller } from '../../services/idp/plan-poller-service';
import {
  copyPlan,
  deletePlan,
  getPlanList,
  resetPlan,
  updatePlanVersion,
} from '../../services/idp/plan-service';
import { setPlanSetup } from './planSetupSlice';
import {
  dispatchThunkError as dispatchThunkErrorUtil,
  pickEmplid,
} from './utils';

const dispatchThunkError = (
  ...args: Parameters<typeof dispatchThunkErrorUtil>
) => {
  const [dispatch, error, thunkFunction] = args;
  dispatchThunkErrorUtil(...args);
  // ===========================================
  dispatch(setPlanLoading(false));
  // ===========================================
  const res = error as HTTPError;
  if (res.status || res.response?.status) {
    const statusCode = res.response?.status || res.status || null;
    dispatch(setHttpStatusCode(statusCode));
  }
  // ===========================================
  if (thunkFunction.typePrefix === getPlanAsync.typePrefix) {
    dispatch(setPlanLoadFailed(true));
  }
  // ===========================================
};

const scrollContainerId = 'degree-plan';

const planWorker = new HttpQueueWorkerManager({ logMessage: true });

const initialState: PlanState = {
  isDefaultState: true,
  httpStatusCode: null,
  loadFailed: false,
  saving: false,
  saved: false,
  saveFailed: false,
  revertVersionFailed: false,
  classMoved: false,
  planLoading: false,
  planLoaded: false,
  planListLoading: false,
  planListLoaded: false,
  lastConsumerEmplid: null,
  currentTerm: null,
  termMap: null,
  dataModified: false,
  dataList: [],
  data: {
    uuid: '',
    isActivePlan: false,
    currentPlanVersion: 0,
    maxPlanVersion: 0,
    catalogYear: '',
    degreeProgram: '',
    planName: '',
    lastModified: null,
    configuration: {
      method: 'graduation-term',
      graduationTerm: '',
      creditHourTerm: undefined,
      includeSummerTerms: false,
    },
    summary: {
      academicPlan: NONE_VALUE,
      academicPlanCode: NONE_VALUE,
      catalogYearDescription: NONE_VALUE,
      cumulativeGpa: '',
      location: NONE_VALUE,
      college: NONE_VALUE,
      targetGraduationTerm: '',
      generalStudiesCurriculumCode: 'M',
      degree: NONE_VALUE,
      requirementSummaries: [],
    },
    transferCredits: {
      courseCredits: [],
      testCredits: [],
    },
    studentProgress: [],
    degreePlan: [],
    validationErrors: {
      termCode: '',
      sessionCode: undefined,
      subject: undefined,
      catalogNumber: undefined,
      validationError: [],
      reason: 'GENERIC',
      maxCreditExceed: undefined,
    },
    buildStatus: 'REQUESTING_DEGREE_AUDIT',
  },
  dataHistory: {
    undoStack: [],
    redoStack: [],
    undoStackActive: false,
    redoStackActive: false,
    lastChangeType: null,
  },
  dataPlanOwner: null,
  warningDnD: {
    hasWarning: false,
    message: undefined,
  },
  replaceCourse: null,
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setReplaceCourse: (
      state,
      { payload }: ActionOf<API.PlanData.ClassDeleteUrlParams | null>,
    ) => {
      state.replaceCourse = payload;
    },
    setWarningDnD: (state, { payload }: ActionOf<WarningDnD>) => {
      state.warningDnD = payload;
    },
    setCurrentTerm: (state, { payload }: ActionOf<DPL_API.TermData.Term>) => {
      state.currentTerm = payload;
    },
    setTermMap: (
      state,
      { payload }: ActionOf<DPL_API.TermData.TermMap | null>,
    ) => {
      state.termMap = payload;
    },
    resetPlanFailFlags: (state) => {
      state.loadFailed = false;
      state.saveFailed = false;
      state.revertVersionFailed = false;
      state.httpStatusCode = null;
    },
    setHttpStatusCode: (state, { payload }: ActionOf<number | null>) => {
      state.httpStatusCode = payload;
    },
    setClassMoved: (state, action: ActionOf<boolean>) => {
      state.classMoved = action.payload;
    },
    setPlanLoading: (state, action: ActionOf<boolean>) => {
      state.planLoading = action.payload;
      state.planLoaded = !action.payload;
    },
    setPlanListLoadFlags: (state, action: ActionOf<boolean>) => {
      state.planListLoading = action.payload;
      state.planListLoaded = !action.payload;
    },
    setPlanListLoading: (state, action: ActionOf<boolean>) => {
      state.planListLoading = action.payload;
    },
    setPlanListLoaded: (state, action: ActionOf<boolean>) => {
      state.planListLoaded = action.payload;
    },
    setPlanSaving: (state, action: ActionOf<boolean>) => {
      state.saving = action.payload;
    },
    setPlanSaved: (state, action: ActionOf<boolean>) => {
      state.saved = action.payload;
    },
    setPlanSaveFailed: (state, action: ActionOf<boolean>) => {
      state.saveFailed = action.payload;
    },
    setPlanLoadFailed: (state, action: ActionOf<boolean>) => {
      state.loadFailed = action.payload;
    },
    setLastConsumerEmplid: (state, action: ActionOf<string>) => {
      state.lastConsumerEmplid = action.payload;
    },
    setPlanName: (state, action: ActionOf<string>) => {
      state.data.planName = action.payload;
    },
    setPlanActiveState: (state, action: ActionOf<boolean>) => {
      state.data.isActivePlan = action.payload;
    },
    setPlanDegree: (state, action: ActionOf<API.PlanData.Term[]>) => {
      state.data.degreePlan = action.payload;
    },
    setPlanToDefault: (state) => {
      state.data = initialState.data;
    },
    setPlan: (state, { payload: rawPlan }: ActionOf<API.PlanData.Plan>) => {
      const { currentTerm, termMap } = state;
      const planData = preProcessPlanForUI({
        rawPlan,
        termMap,
        currentTerm,
      })!;

      state.data = planData;
      state.isDefaultState = false;
    },
    setPlanList: (state, action: ActionOf<API.PlanData.PlanRecord[]>) => {
      state.dataList = action.payload;
    },
    setPlanOwner: (state, action: ActionOf<API.StudentData.Profile>) => {
      state.dataPlanOwner = action.payload;
    },
    movePlanClass: (state, action: ActionOf<ClassDragItemData>) => {
      moveClass(state, action);
    },
    resetPlanHistory: (state) => {
      state.dataHistory = initialState.dataHistory;
    },
    revertPlanChanges: (
      state,
      { payload: { direction } }: ActionOf<{ direction: HistoryDirection }>,
    ) => {
      // //===========================================================
      const { lastHistoryItem, pastStack, futureStack, undoStack, redoStack } =
        processHistoryStacks(state.dataHistory, direction);
      //===========================================================
      if (!lastHistoryItem) {
        return;
      }
      //===========================================================
      const oldPlanSnapshot = state.data;
      const { changeType, currentPlanVersion } = lastHistoryItem;

      pastStack.pop();

      const historyItem = {
        changeType,
        currentPlanVersion,
        planSnapshot: oldPlanSnapshot,
      } as PlanHistoryItem;

      if (changeType === 'CLASS_MOVE') {
        const { classChange } = lastHistoryItem;
        historyItem.classChange = classChange;
        scrollToClassCard(classChange.source.classUId);
      } else if (changeType === 'CLASS_ADD') {
        const { classChange } = lastHistoryItem;
        historyItem.classChange = classChange;
      } else if (changeType === 'SETUP_UPDATE') {
        const { setupChange } = lastHistoryItem;

        historyItem.setupChange = {
          newSetup: setupChange.oldSetup,
          oldSetup: setupChange.newSetup,
        };
      }
      futureStack.push(historyItem);
      //===========================================================
      state.dataHistory.undoStackActive = undoStack.length > 0;
      state.dataHistory.redoStackActive = redoStack.length > 0;
      //===========================================================
    },
    trackPlanChanges: (
      state,
      {
        payload: { changeType, setupChange, classChange },
      }: ActionOf<PlanHistoryInput>,
    ) => {
      state.dataHistory.undoStackActive = true;
      state.dataHistory.lastChangeType = changeType;
      //===========================================================
      const planSnapshot = cloneDraftObject<API.PlanData.Plan>(state.data);
      const { undoStack, redoStack } = state.dataHistory;
      const { currentPlanVersion } = state.data;

      const historyItem = {
        changeType,
        currentPlanVersion,
        planSnapshot,
      } as PlanHistoryItem;

      if (changeType === 'SETUP_UPDATE') {
        historyItem.setupChange = setupChange;
      } else {
        historyItem.classChange = classChange;
      }
      //===========================================================
      // if save a change after click UNDO, reset following history
      //===========================================================
      if (redoStack.length > 0) {
        state.dataHistory.redoStack = [];
        state.dataHistory.redoStackActive = false;
      }
      //===========================================================
      // if reach the MAX_CHANGES remove the oldest history snapshot
      // to make space to the new newest one
      //===========================================================
      const MAX_CHANGES = 20;
      if (undoStack.length === MAX_CHANGES) {
        undoStack.shift();
      }
      //===========================================================
      undoStack.push(historyItem);

      state.dataHistory.undoStackActive = undoStack.length > 0;
    },
  },
});

const processHistoryStacks = (
  dataHistory: DataHistory,
  direction: HistoryDirection,
) => {
  const { undoStack, redoStack } = dataHistory;
  let pastStack: PlanHistoryItem[], futureStack: PlanHistoryItem[];

  const isUndo = direction === 'undo';

  if (isUndo) {
    pastStack = undoStack;
    futureStack = redoStack;
  } else {
    pastStack = redoStack;
    futureStack = undoStack;
  }

  const lastHistoryItem = pastStack[pastStack.length - 1];

  return {
    isUndo,
    pastStack,
    futureStack,
    undoStack,
    redoStack,
    lastHistoryItem,
  };
};

/**
 * This function update the plan state in 2 different use cases:
 * CASE 1: user Drag & Drop a class from one term/session to another
 * CASE 2: user click UNDO/REDO to revert a class move
 * @returns Indicate move has be done
 */
const moveClass = (
  state: PlanState,
  { payload }: ActionOf<ClassDragItemData>,
): boolean => {
  const { source, target } = payload;
  const planTerms = state.data.degreePlan;
  const sourceListType: Record<
    API.PlanData.ClassType,
    'classes' | 'requiredClasses' | 'selectedClasses'
  > = {
    enrolled: 'classes',
    required: 'requiredClasses',
    selected: 'selectedClasses',
  };
  const SOURCE_LIST_TYPE = sourceListType[source.classType] || 'classes';
  //===========================================================
  // source
  //===========================================================
  const sourceTerm = planTerms.find((term) => term._uid === source.termUId);
  const sourceSession = sourceTerm?.sessions.find(
    (sessions) => sessions._uid === source.sessionUId,
  )!;
  const sourceClasses = sourceSession?.[SOURCE_LIST_TYPE] || [];

  const classItem = sourceClasses.find(
    (sClass) => sClass._uid === source.classUId,
  );

  //===========================================================
  // EDGE CASE: USER action conflicts REDO action
  //===========================================================
  // 1. Move a class-x FROM Term-x/Session-x TO Term-y/Session-y
  // 2. Click UNDO
  // 3. Repeat step 1
  // 4. Click REDO
  // Step 4 creates a history conflict use the user manual action,
  // and eventually it would throws an error.
  //===========================================================
  // FIX: if a class is not found inside the `source` history,
  // the action will be considered already executed.
  //===========================================================
  if (!classItem) return false;
  //===========================================================

  // remove source class item
  sourceSession[SOURCE_LIST_TYPE] = sourceClasses.filter(
    (sClass) => sClass._uid !== source.classUId,
  );
  //===========================================================
  // target
  //===========================================================
  const targetTerm = planTerms.find((term) => term._uid === target.termUId);
  const targetSession = targetTerm?.sessions.find(
    (session) => session._uid === target.sessionUId,
  )!;

  // add class item to target
  classItem._uiMetaData = {
    ...classItem._uiMetaData!,
    termUId: target.termUId,
    sessionUId: target.sessionUId,
    saving: true,
  };
  targetSession[SOURCE_LIST_TYPE]?.push(classItem);
  //===========================================================
  state.dataModified = true;

  return true;
};

function setPlanFromPoller(
  emplid: string,
  planId: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  thunkFunction: { typePrefix: string },
): Promise<void> {
  return startPlanPoller({
    emplid,
    planId,
    onSuccess: async ({ setup, plan }) => {
      const termMap =
        plan.degreePlan.length > 0
          ? await getTermByRange([
              'curr',
              plan.degreePlan[plan.degreePlan.length - 1].term,
            ])
          : null;

      dispatch(setLastConsumerEmplid(emplid));
      dispatch(setTermMap(termMap));
      dispatch(setPlan(plan));
      dispatch(setPlanSetup(setup!));
      dispatch(setPlanLoading(false));
    },
    onError: async (error) => {
      dispatchThunkError(dispatch, error, thunkFunction);
    },
  });
}

export const revertPlanChangesAsync = createAsyncThunk(
  'planSlice/revertPlanChangesAsync',
  async (
    payload: {
      planId: string;
      direction: HistoryDirection;
    },
    { dispatch, getState },
  ) => {
    dispatch(resetPlanFailFlags());
    dispatch(setPlanLoading(true));
    dispatch(setClassMoved(false));
    try {
      const { planId, direction } = payload;
      const emplid = pickEmplid(getState());

      await updatePlanVersion(emplid, planId, direction);
      await setPlanFromPoller(emplid, planId, dispatch, revertPlanChangesAsync);

      dispatch(
        revertPlanChanges({
          direction,
        }),
      );

      return true;
    } catch (error) {
      dispatchThunkError(dispatch, error, revertPlanChangesAsync);
      return false;
    }
  },
);

export const getPlanListAsync = createAsyncThunk(
  'planSlice/getPlanListAsync',
  async (
    param:
      | {
          searchEmplId?: string;
        }
      | undefined,
    { dispatch, getState, signal },
  ) => {
    dispatch(setPlanListLoadFlags(true));
    dispatch(setPlanList([]));
    try {
      const emplid = param?.searchEmplId || pickEmplid(getState());
      const dataList = await getPlanList(emplid, signal);
      const sortedList = sortPlanByDate(dataList);

      dispatch(setPlanList(sortedList));
      dispatch(setPlanListLoadFlags(false));
      return true;
    } catch (error) {
      dispatch(setPlanListLoading(false));
      dispatchThunkError(dispatch, error, getPlanListAsync);
    }
  },
);

export const getPlanListSilentlyAsync = createAsyncThunk(
  'planSlice/getPlanListSilentlyAsync',
  async (_payload, { dispatch, getState, signal }) => {
    try {
      const emplid = pickEmplid(getState());
      const dataList = await getPlanList(emplid, signal);
      const sortedList = sortPlanByDate(dataList);

      dispatch(setPlanList(sortedList));

      return {
        success: true,
        fetchedPlanList: sortedList,
      };
    } catch (error) {
      dispatchThunkError(dispatch, error, getPlanListSilentlyAsync);
      return {
        success: false,
        fetchedPlanList: [],
      };
    }
  },
);

export const getPlanAsync = createAsyncThunk(
  'planSlice/getPlanAsync',
  async (
    payload: {
      planId: string;
      searchEmplId?: string;
    },
    { dispatch, getState },
  ) => {
    dispatch(resetPlanFailFlags());
    dispatch(setPlanLoading(true));

    try {
      const { planId, searchEmplId } = payload;
      const emplid = searchEmplId || pickEmplid(getState());

      if (!planId) {
        dispatch(setPlanLoading(false));
        dispatch(setPlanLoadFailed(true));
        return;
      }

      if (searchEmplId) {
        const planOwner = await getStudentProfile(searchEmplId);
        dispatch(setPlanOwner(planOwner));
      }

      await setPlanFromPoller(emplid, planId, dispatch, getPlanAsync);
    } catch (error) {
      dispatchThunkError(dispatch, error, getPlanAsync);
    }
  },
);

export const addCourseAsync = createAsyncThunk(
  'planSlice/addCourseAsync',
  async (
    {
      planId,
      data,
    }: {
      planId: string;
      data: API.PlanData.ClassCreatePayload;
    },
    { dispatch, getState },
  ) => {
    try {
      dispatch(resetPlanFailFlags());

      const emplid = pickEmplid(getState());
      // =====================================
      // Replace course
      // =====================================
      const state = getState() as AppState;
      const replaceCourse = state.plan.replaceCourse;
      if (replaceCourse) {
        await deletePlanCourse(emplid, planId, replaceCourse);
        dispatch(setReplaceCourse(null));
      }
      // =====================================
      await createPlanCourse(emplid, planId, data);
      await setPlanFromPoller(emplid, planId, dispatch, getPlanAsync);

      return true;
    } catch (error) {
      dispatchThunkError(dispatch, error, addCourseAsync);
      return false;
    }
  },
);

export const deleteCourseAsync = createAsyncThunk(
  'planSlice/deleteCourseAsync',
  async (
    {
      planId,
      data,
    }: {
      planId: string;
      data: API.PlanData.ClassDeleteUrlParams;
    },
    { dispatch, getState },
  ) => {
    try {
      dispatch(resetPlanFailFlags());
      dispatch(setPlanLoading(true));

      const emplid = pickEmplid(getState());
      await deletePlanCourse(emplid, planId, data);
      await setPlanFromPoller(emplid, planId, dispatch, getPlanAsync);

      return true;
    } catch (error) {
      dispatchThunkError(dispatch, error, deleteCourseAsync);
      return false;
    }
  },
);

export const movePlanClassAsync = createAsyncThunk(
  'planSlice/movePlanClassAsync',
  async (
    payload: {
      planId: string;
      dragItem: ClassDragItemData;
    },
    { dispatch, getState },
  ) => {
    dispatch(setPlanSaving(true));
    dispatch(setPlanSaved(false));
    dispatch(setPlanSaveFailed(false));
    dispatch(setClassMoved(false));

    const { planId, dragItem } = payload;
    const emplid = pickEmplid(getState());

    try {
      planWorker.addSharedEventListener(
        'message',
        async (evt: MessageEvent<OnMessageData>) => {
          if (evt.data.action === 'success') {
            const dragItem = evt.data.payload.request.tags
              .dragItem as ClassDragItemData;

            dispatch(
              trackPlanChanges({
                changeType: 'CLASS_MOVE',
                classChange: dragItem,
              }),
            );

            const { isQueueEmpty } = evt.data;

            if (isQueueEmpty) {
              planWorker.removeAllEventListener('message');

              dispatch(setPlanSaving(false));
              dispatch(setPlanSaved(true));
              await setPlanFromPoller(
                emplid,
                planId,
                dispatch,
                movePlanClassAsync,
              );
              dispatch(setClassMoved(true));

              scrollToMovedClass({
                termCode: dragItem.target.termCode,
                sessionCode: dragItem.target.sessionCode,
                cardId: dragItem.source.cardId,
              });
            }
          } else if (evt.data.action === 'error') {
            planWorker.removeAllEventListener('message');

            const error = evt.data.payload;
            dispatch(setPlanSaving(false));
            dispatch(setPlanSaveFailed(true));
            dispatchThunkError(dispatch, error, movePlanClassAsync);
            setPlanFromPoller(emplid, planId, dispatch, movePlanClassAsync);
          }
        },
      );

      updatePlanCourses(emplid, planId, dragItem, planWorker);
    } catch (error) {
      dispatchThunkError(dispatch, error, movePlanClassAsync);
    }
  },
);

export const deletePlanAsync = createAsyncThunk(
  'planSlice/deletePlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    try {
      const emplid = pickEmplid(getState());
      const res = await deletePlan(emplid, planId);

      return res;
    } catch (error) {
      dispatchThunkError(dispatch, error, deletePlanAsync);
      return false;
    }
  },
);

export const copyPlanAsync = createAsyncThunk(
  'planSlice/copyPlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    try {
      const emplid = pickEmplid(getState());
      const copiedPlanId = await copyPlan(emplid, planId);

      return copiedPlanId;
    } catch (error) {
      dispatchThunkError(dispatch, error, copyPlanAsync);
      return null;
    }
  },
);

export const resetPlanAsync = createAsyncThunk(
  'planSlice/resetPlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    try {
      dispatch(resetPlanFailFlags());

      const emplid = pickEmplid(getState());
      await resetPlan(emplid, planId);
      await setPlanFromPoller(emplid, planId, dispatch, resetPlanAsync);

      return true;
    } catch (error) {
      dispatchThunkError(dispatch, error, resetPlanAsync);
      return false;
    }
  },
);

export const getCurrentTermAsync = createAsyncThunk(
  'planSlice/getCurrentTermAsync',
  async (_, { dispatch }) => {
    try {
      const currentTerm = await getCurrentTerm();
      dispatch(setCurrentTerm(currentTerm));
    } catch (error) {
      dispatchThunkError(dispatch, error, getCurrentTermAsync);
    }
  },
);

export const getDefaultPlanData = () => {
  return {
    ...initialState.data,
  };
};

export const {
  resetPlanFailFlags,
  setHttpStatusCode,
  setPlanLoading,
  setPlanListLoading,
  setPlanListLoaded,
  setPlanListLoadFlags,
  setPlanOwner,
  setPlanName,
  setPlanToDefault,
  setPlanDegree,
  setPlanActiveState,
  setPlan,
  setPlanList,
  setPlanSaving,
  setPlanSaved,
  setPlanSaveFailed,
  setPlanLoadFailed,
  movePlanClass,
  revertPlanChanges,
  resetPlanHistory,
  trackPlanChanges,
  setLastConsumerEmplid,
  setCurrentTerm,
  setTermMap,
  setClassMoved,
  setWarningDnD,
  setReplaceCourse,
} = planSlice.actions;

export { scrollContainerId };

export default planSlice.reducer;
