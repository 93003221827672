import { appRouterUrl, startPageByRole } from 'core/constants/router-url';
import { useAppSelector } from '..';
import { pickCurrentRoleSelector } from '../slices/utils';

export const useDashboardUrlSelector = () =>
  useAppSelector((state) => {
    const role =
      state.userProfile.viewAs.currentRole || state.userProfile.currentRole;

    const dashboard = role
      ? startPageByRole[role] || appRouterUrl.NOT_FOUND
      : appRouterUrl.NOT_FOUND;

    return dashboard;
  });

// ## select a slice of contactInfo
export const useContactValidatorSelector = (
  key: keyof StudentProfileState['validator'],
) => {
  return useAppSelector((state) => state.studentProfile.validator[key]);
};
// ## select the current role
export const useCurrentRoleSelector = () =>
  useAppSelector(pickCurrentRoleSelector);

export * from './use-current-profile-selector';
export * from './use-plan-extra-data-selector';
export * from './use-plan-warning-selector';
export * from './use-term-selector';
export * from './use-term-session-selector';
export * from './use-user-roles-selector';
