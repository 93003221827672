import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import 'App.css';
import { ProtectedRoute } from 'core/auth';
import { LazyRoute } from 'core/components';
import {
  roleList,
  RoleName,
  SUFFIX_OTHER_VERSION,
} from 'core/constants/app-constants';
import { appRouterUrl } from 'core/constants/router-url';
import { useScrollbarSettings } from 'core/hooks/use-scrollbar-settings';
import { useWebpackError } from 'core/hooks/use-webpack-error';
import { useAppDispatch } from 'core/store';
import {
  useCurrentRoleSelector,
  useDashboardUrlSelector,
} from 'core/store/selectors';
import { setSelectedRoute, setShowNavbar } from 'core/store/slices';
//#region "pages"
import { PageLayout } from 'pages/PageLayout';

const Dashboard = React.lazy(() => import('./pages/Student/Dashboard'));
const PlanList = React.lazy(() => import('./pages/Student/PlanList'));
const PlanDetail = React.lazy(() => import('./pages/Student/PlanDetail'));
const ComparisonList = React.lazy(
  () => import('./pages/Student/ComparisonList'),
);
const StudentProfile = React.lazy(() => import('./pages/Student/Profile'));
const AdminDashboard = React.lazy(() => import('./pages/Admin/Dashboard'));
const AdminProfileView = React.lazy(() => import('./pages/Admin/ProfileView'));
const AdminReports = React.lazy(() => import('./pages/Admin/Reports'));
const AdminSettings = React.lazy(() => import('./pages/Admin/Settings'));

const NotAuthorized = React.lazy(() => import('./pages/NotAuthorized'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const LogOut = React.lazy(() => import('./pages/LogOut'));
//#endregion

const allRoles = roleList;
const adminRoles = [RoleName.ADMIN, RoleName.PLATFORM];
const advisorRoles = [RoleName.ADVISOR, RoleName.COACH];

// TODO: remove  once V2 is done
const PLAN_DETAIL = appRouterUrl.PLAN_DETAIL;
const PLAN_DETAIL_OTHER_VERSION =
  SUFFIX_OTHER_VERSION + appRouterUrl.PLAN_DETAIL;

function tempAdjustRouterForOtherVersion(pathname: string) {
  const isOtherVersion = pathname.includes(SUFFIX_OTHER_VERSION);
  const isPlanDetailOV =
    appRouterUrl.PLAN_DETAIL.startsWith(SUFFIX_OTHER_VERSION);

  if (isOtherVersion && !isPlanDetailOV) {
    // @ts-ignore
    appRouterUrl.PLAN_DETAIL = PLAN_DETAIL_OTHER_VERSION;
  } else if (!isOtherVersion && isPlanDetailOV) {
    // @ts-ignore
    appRouterUrl.PLAN_DETAIL = PLAN_DETAIL;
  }
}

function App() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const currentRole = useCurrentRoleSelector();
  const dashboardPage = useDashboardUrlSelector();

  useWebpackError();
  useScrollbarSettings();

  useEffect(() => {
    tempAdjustRouterForOtherVersion(pathname);

    if (pathname === appRouterUrl.HOME) {
      dispatch(setShowNavbar(true));
    }
    dispatch(setSelectedRoute(pathname));
  }, [dispatch, pathname]);

  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route
          path={appRouterUrl.HOME}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <Navigate to={dashboardPage} />
            </ProtectedRoute>
          }
        />

        {/* =============================================
                         "Student pages"
            ============================================= */}
        <Route
          path={appRouterUrl.STUDENT_PROFILE}
          element={
            <ProtectedRoute
              viewAsRole={currentRole}
              roles={[RoleName.STUDENT]}
              lazy
            >
              <StudentProfile />
            </ProtectedRoute>
          }
        />

        {[
          `${SUFFIX_OTHER_VERSION}${appRouterUrl.STUDENT_DASHBOARD}`,
          appRouterUrl.STUDENT_DASHBOARD,
        ].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute
                viewAsRole={currentRole}
                roles={[RoleName.STUDENT]}
                lazy
              >
                <Dashboard />
              </ProtectedRoute>
            }
          />
        ))}

        {[
          `${SUFFIX_OTHER_VERSION}${appRouterUrl.PLAN_DETAIL_BY_PLAN_ID}`,
          appRouterUrl.PLAN_DETAIL_BY_PLAN_ID,
        ].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <ProtectedRoute
                viewAsRole={currentRole}
                roles={[RoleName.STUDENT]}
                lazy
              >
                <PlanDetail />
              </ProtectedRoute>
            }
          />
        ))}

        <Route
          path={appRouterUrl.PLAN_VIEW_BY_EMPLID_PLAN_ID}
          element={
            <ProtectedRoute roles={[...adminRoles, ...advisorRoles]} lazy>
              <PlanDetail />
            </ProtectedRoute>
          }
        />

        {/* @deprecated - not in scope for v1 */}
        <Route
          path={appRouterUrl.COMPARISON_LIST}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <ComparisonList />
            </ProtectedRoute>
          }
        />

        {/* @deprecated - not in scope for v1 */}
        <Route
          path={appRouterUrl.PLANS_LIST}
          element={
            <ProtectedRoute roles={allRoles} lazy>
              <PlanList />
            </ProtectedRoute>
          }
        />

        {/* =============================================
                         "Admin pages"
            ============================================= */}
        <Route
          path={appRouterUrl.ADMIN_DASHBOARD}
          element={
            <ProtectedRoute roles={[...adminRoles, ...advisorRoles]} lazy>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.ADMIN_PROFILE_VIEW_BY_ID}
          element={
            <ProtectedRoute roles={[...adminRoles, ...advisorRoles]} lazy>
              <AdminProfileView />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.ADMIN_SETTINGS}
          element={
            <ProtectedRoute roles={adminRoles} lazy>
              <AdminSettings />
            </ProtectedRoute>
          }
        />

        <Route
          path={appRouterUrl.ADMIN_REPORTS}
          element={
            <ProtectedRoute roles={adminRoles} lazy>
              <AdminReports />
            </ProtectedRoute>
          }
        />
        {/* =============================================
                         "Other pages"
            ============================================= */}
        <Route
          path={appRouterUrl.LOG_OUT}
          element={
            <LazyRoute>
              <LogOut />
            </LazyRoute>
          }
        />
        <Route
          path={appRouterUrl.NOT_AUTHORIZED}
          element={
            <LazyRoute>
              <NotAuthorized />
            </LazyRoute>
          }
        />
        {['*', appRouterUrl.NOT_FOUND].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <LazyRoute>
                <NotFound />
              </LazyRoute>
            }
          />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
