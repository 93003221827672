import { createSelector } from '@reduxjs/toolkit';

import { RootState, useAppSelector } from '..';

type Validator = API.PlanData.PlanUIMetaData['validator'];

const emptyWarnings: Validator['plainWarnings'] = [];
const emptyGroupedWarnings: Validator['groupedWarnings'] = [];

const memoSelector = createSelector(
  (state: RootState) => state.plan,
  (plan) => {
    const validator = plan.data?._uiMetaData?.validator;
    const warnings = validator?.plainWarnings || emptyWarnings;
    const groupedWarnings = validator?.groupedWarnings || emptyGroupedWarnings;
    const warningCount = warnings.length;

    return { warnings, groupedWarnings, warningCount };
  },
);

const usePlanWarningsSelector = () =>
  useAppSelector((state) => {
    return memoSelector(state);
  });

export { usePlanWarningsSelector };
