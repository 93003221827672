import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '..';

const useUserRolesSelector = () => {
  return useAppSelector((state) => {
    const memoSelector = createSelector(
      (state: RootState) => state.userProfile,
      (userProfile) => {
        const viewAsRoles = userProfile.viewAs?.selectedUser?.roles;
        const profileRoles = userProfile.roles;

        return viewAsRoles || profileRoles || [];
      },
    );
    return memoSelector(state);
  });
};

export { useUserRolesSelector };
